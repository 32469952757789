import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { flatten } from 'lodash'
import { Flex } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionGold, SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import PinList from '../components/pin-list'
import Locations from '../components/locations'
import NextStep from '../components/next-step'
import normalizeLocations from '../utils/normalizeLocations'
import getTranslations from '../utils/getTranslations'
import getBreweryLocation from '../utils/getBreweryLocation'

const LocationsPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, rawLocations, heroBackgroundImage, nextStepBackground } = data
  const { title, content, acf, polylang_translations: translations } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf

  if (Array.isArray(rawLocations.edges)) {
    const locations = normalizeLocations(flatten(rawLocations.edges))
    locations.push(getBreweryLocation())

    return (
      <Layout
        locale={locale}
        pageTranslationLinks={getTranslations(locale, translations)}
      >
        <SEO title={title} />

        <Hero
          title={title}
          html={content}
          backgroundImage={heroBackgroundImage}
          backgroundPosition="50% 30%"
        />

        <SectionGold px={5} pt={[3, 4]} pb={[3, 4]}>
          <Container>
            <Flex
              minHeight="3.25rem"
              justifyContent="center"
              alignItems="center"
            >
              <PinList />
            </Flex>
          </Container>
        </SectionGold>

        <SectionWithObjects>
          <Container>
            <Locations locations={locations} />
          </Container>
        </SectionWithObjects>

        <NextStep
          title={textKamDal || ''}
          link={odkazKamDal}
          label={popisekTlacitkaKamDal}
          backgroundImage={nextStepBackground}
        />
      </Layout>
    )
  }

  return null
}

LocationsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default LocationsPage

export const pageQuery = graphql`
  query LocationsPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    rawLocations: allWordpressWpVenueType {
      edges {
        node {
          acf {
            typ_podniku
            adresa
            odkaz
            popisek_odkazu
            stale_na_cepu
            nazev_podniku
            pin_na_mape {
              lat
              lng
            }
          }
          title
          slug
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "kam-na-pivo.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "nabizime.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
