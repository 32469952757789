import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Image, Text } from 'rebass/styled-components'

import { PinBrewery, PinTap, PinRetail } from './pins'

const LocationType = (props) => (
  <Flex sx={{ alignItems: 'center', mr: [3, 4], my: [2, 0] }} {...props} />
)

const PinImage = (props) => (
  <Image
    sx={{ width: ['1.5rem', '2rem'], height: ['1.5rem', '2rem'], mr: [1, 2] }}
    aria-hidden="true"
    {...props}
  />
)

const LocationText = (props) => (
  <Text sx={{ m: 0, p: 0, color: 'darkGray', fontSize: [7, 8] }} {...props} />
)

const PinList = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['row'],
        justifyContent: ['space-between', 'center'],
        flexWrap: 'wrap',
      }}
    >
      <LocationType>
        <PinImage src={PinBrewery} alt="" />

        <LocationText>
          <FormattedMessage
            id="locations.ourBrewery"
            defaultMessage="Náš pivovar"
          />
        </LocationText>
      </LocationType>

      <LocationType>
        <PinImage src={PinTap} alt="" />

        <LocationText>
          <FormattedMessage id="locations.tap" defaultMessage="Výčep" />
        </LocationText>
      </LocationType>

      <LocationType>
        <PinImage src={PinRetail} alt="" />

        <LocationText>
          <FormattedMessage
            id="locations.retail"
            defaultMessage="Prodej piva"
          />
        </LocationText>
      </LocationType>
    </Flex>
  )
}

export default PinList
