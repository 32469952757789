import { useStaticQuery, graphql } from 'gatsby'

const getBreweryLocation = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company {
              name
              address {
                addressLine1
                addressLine2
                city
                postalCode
              }
              lat
              lng
            }
          }
        }
      }
    `,
  )

  if (site) {
    const { name, address, lat, lng } = site.siteMetadata.company
    const { addressLine1, addressLine2, city, postalCode } = address
    const breweryAddress = `${addressLine1}\n${addressLine2}\n${postalCode} ${city}`

    return {
      key: 'brewery',
      name,
      type: 'brewery',
      address: breweryAddress || '',
      lat: Number(lat) || 0,
      lng: Number(lng) || 0,
      stable: false,
      links: [],
    }
  }

  return null
}

export default getBreweryLocation