const types = {
  vycep: 'tap',
  pivovar: 'brewery',
  'prodej-piva': 'retail',
}

export const normalizeLocation = (location) => {
  return {
    key: location.slug,
    name: location.acf.nazev_podniku || location.title,
    type: types[location.acf.typ_podniku] || '',
    address: location.acf.adresa || '',
    lat: Number(location.acf.pin_na_mape.lat) || 0,
    lng: Number(location.acf.pin_na_mape.lng) || 0,
    stable: location.acf.stale_na_cepu === null,
    links: [
      {
        label: location.acf.popisek_odkazu,
        url: location.acf.odkaz,
      },
    ],
  }
}

const normalizeLocations = (locations) => {
  if (Array.isArray(locations)) {
    const normalizedLocations = []

    locations.forEach((locationNode) => {
      const location = locationNode.node

      const newLocation = normalizeLocation(location)

      normalizedLocations.push(newLocation)
    })

    return normalizedLocations
  }
}

export default normalizeLocations
