import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { useDebounce } from 'use-debounce'
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components'
import { Input } from '@rebass/forms'

import Map from './map'
import { PinTap, PinBrewery, PinRetail } from './pins'
import StyledMapWrapper from './styled-map-wrapper'

const getMarkerIcon = (type) => {
  switch (type) {
    case 'brewery':
      return PinBrewery
    default:
    case 'tap':
      return PinTap
    case 'retail':
      return PinRetail
  }
}

const getLocationColor = (type) => {
  switch (type) {
    case 'brewery':
      return 'gold'
    default:
    case 'tap':
      return 'green'
    case 'retail':
      return 'blue'
  }
}

const getMarkers = (locations) => {
  return locations.map((location) => {
    return {
      key: location.key,
      lat: Number(location.lat),
      lng: Number(location.lng),
      icon: getMarkerIcon(location.type, location.stable),
      title: location.name,
    }
  })
}

const Location = ({ location, onClick }) => {
  return (
    <Box
      key={location.key}
      variant="location"
      onClick={() => onClick({ lat: location.lat, lng: location.lng })}
      sx={{
        borderLeftColor: getLocationColor(location.type, location.stable),
        borderLeftStyle: 'solid',
        borderLeftWidth: '4px',
      }}
    >
      <Heading as="h2" variant="locationName">
        {location.name}
      </Heading>

      <Text as="p" variant="body" sx={{ m: 0, fontSize: 4 }}>
        {location.address}
      </Text>
    </Box>
  )
}

const Locations = ({ locations, intl }) => {
  const [mapCenter, setMapCenter] = useState(null)
  const [mapZoom, setMapZoom] = useState(null)
  const [filter, setFilter] = useState('')

  const [debouncedFilter] = useDebounce(filter, 1000)

  const handleLocationClick = (position, zoom = 16) => {
    setMapCenter(position)
    setMapZoom(zoom)
  }

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        justifyContent: ['flex-start', 'space-between'],
      }}
    >
      <Flex
        sx={{
          width: ['100%', '30%'],
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Input
            sx={{
              px: 3,
              py: 2,
              bg: '#474747',
              border: 'none',
              borderRadius: '32px',
              color: 'white',
              fontFamily: 'inherit',
              lineHeight: '1.75',
              outline: 'none',
            }}
            id="filter"
            name="filter"
            type="text"
            placeholder={intl.formatMessage({ id: 'locations.filter.placeholder', defaultMessage: 'Hledejte podle podniku nebo města...' })}
            value={filter}
            onChange={(e) => {
              setFilter(e.currentTarget.value)
            }}
          />
        </Box>

        <Box
          sx={{
            overflow: 'scroll',
            height: 'calc(400px - 44px)',
          }}
        >
          <Box sx={{ height: '100%', }}>
            {locations && locations.filter((location) => {
              const compareName = location.name.toLowerCase().indexOf(debouncedFilter ? debouncedFilter.toLowerCase() : '')
              const compareCity = location.address.toLowerCase().indexOf(debouncedFilter ? debouncedFilter.toLowerCase() : '')
              return compareName > -1 || compareCity > -1
            }).map((location) => {
              return (
                <Location
                  key={location.key}
                  location={location}
                  onClick={(position) => handleLocationClick(position)}
                />
              )
            })}
          </Box>
        </Box>

        <Box sx={{
          position: 'absolute',
          width: '100%',
          height: '2rem',
          right: 0,
          bottom: 0,
          left: 0,
          background: 'linear-gradient(to top, #333, rgba(20, 20, 20, 0))',
        }}
        />
      </Flex>

      <Box
        sx={{
          width: ['100%', '66%'],
        }}
      >
        <Map
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCFafjdD1i1-UT6uN4z7WVt4lSw4Aotbjk&amp;v=3.exp&amp;libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<StyledMapWrapper />}
          mapElement={<div style={{ height: '100%' }} />}
          center={mapCenter}
          zoom={mapZoom}
          markers={getMarkers(locations)}
        />
      </Box>
    </Flex>
  )
}

Location.propTypes = {
  onClick: PropTypes.func.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    stable: PropTypes.bool,
  }),
}

Location.defaultProps = {
  location: {
    stable: false,
  }
}

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Locations)
